<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="6"
      class="leftPanel align-center"
      :class="{ 'full-page': $vuetify.breakpoint.mdAndUp }"
    >
      <ta-left-intro />
    </v-col>
    <v-col cols="12" md="6" class="justify-center align-center">
      <div class="ma-5 pa-0">
        <vimeo-player
          :video-id="vimeo.intro"
          class="embed-container"
          :options="{ responsive: true }"
        ></vimeo-player>

        <br />
        Beste betrokkene bij het maken van een familiestatuut voor de familie
        {{ family.data.family_name }}.<br /><br />

        Mijn naam is {{ family.data.user.first_name }}
        {{ family.data.user.last_name }} en namens BDO begeleid ik de familie
        {{ family.data.family_name }} bij het beschrijven van het
        Familiestatuut.<br />
        <br />

        U bent uitgenodigd om aan de familiebijeenkomsten deel te nemen.
        Voorafgaand aan elke familiebijeenkomst vragen wij alle deelnemers om
        input over bepaalde perspectieven op het familiebedrijf.
        <br />

        <br />
        Met de Familiestatuut App bieden wij u de mogelijkheid om uw input te
        geven aan de hand van een gestructureerde vragenlijst. Uw input geeft
        ons een beter begrip van uw visie op de toekomst van het familiebedrijf
        en de rol van de familie hierbij. Samen met de visie van de andere
        deelnemers hebben we een compleet beeld van de wensen en verwachtingen.
        Hierdoor kunnen we in de familiebijeenkomsten gerichter toewerken naar
        het vastleggen van de afspraken die de familie wil maken.
        <br />
        Op de volgende pagina komt u in uw persoonlijke deel van de
        Familiestatuut App die wij voor u hebben klaargezet.
        <br />
        <br />

        U kunt de Familiestatuut App in uw eigen tijd en tempo doornemen en
        invullen. Voor elke volgende familiebijeenkomst zullen er nieuwe modules
        voor u worden klaargezet. Heeft u vragen of opmerkingen over de
        Familiestatuut App, dan kunt u mij bereiken op onderstaande
        contactgegevens. <br />
        <br />Alvast dank en tot de volgende familiebijeenkomst.<br />
        Met vriendelijke groet,<br />
        <br />
        <br />
        {{ family.data.user.first_name }} {{ family.data.user.last_name }}<br />
        <br />

        <h3 class="primary--text">
          BDO Accountants & Adviseurs
          <br />

          {{ family.data.user.first_name }} {{ family.data.user.last_name
          }}<br />
          e-mail {{ family.data.user.email }}<br />
          telefoon: {{ family.data.user.telephone }} <br />
        </h3>
        <div class="text-end mr-15">
          <v-btn
            elevation="15"
            class="primary"
            @click="start"
            :disabled="!valid"
            :class="{ 'primary white--text': valid, disabled: !valid }"
            >Verder</v-btn
          >
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import vimeoData from "@/text/vimeo.json";
import { mapGetters } from "vuex";

export default {
  name: "Intro",
  data() {
    return {
      companyName: null,
      firstNameUser: null,
      lastNameUser: null,
      emailUser: null,
      telephoneUser: null,
      valid: true,
      vimeo: vimeoData,
    };
  },
  methods: {
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    start() {
      this.$router.push({ name: "Login" });
    },
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      this.$vuetify.theme.dark = theme === "true";
    }
    this.firstNameUser = JSON.parse(localStorage.getItem("firstNameUser"));
    this.lastNameUser = JSON.parse(localStorage.getItem("lastNameUser"));
    this.companyName = JSON.parse(localStorage.getItem("companyName"));
    this.emailUser = JSON.parse(localStorage.getItem("emailUser"));
    this.telephoneUser = JSON.parse(localStorage.getItem("telephoneUser"));
  },
  computed: {
    ...mapGetters("auth", ["family"]),
  },
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal !important; /* maybe !important  */
}

.height-col {
  height: 100vh;
}
</style>
